import React from "react";

const Calltoaction = () => {
  return (
    <div>
      <section
        className="call-to-action "
        style={{ backgroundColor: "#d0a35e" }}
      >
        <div className="col-inner">
          <div
            id="nz-tagline-1"
            style={{ background: "#d0a35" }}
            className="nz-tagline"
          >
            <div className="container nz-clearfix">
              <span
                style={{ color: "#ffffff" }}
                className="tagline-icon icon-bulb"
              ></span>
              <span className="tagline-title" style={{ color: "#fff" }}>
                Get a quick consultation and our experts are here to help you
                out
              </span>
              <a
                className="button button-normal white square icon-true full-false medium animate-false anim-type-ghost hover-fill"
                href="http://www.globalconsol.com"
              >
                <span className="txt">Reach Us</span>
                <span className="btn-icon icon-info"></span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Calltoaction;
