import React, { useEffect, useState } from "react";
import Banner from "../../Combonent/Banner";
import axios from "../../utils/axios";
import { Link } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";

const Contact = ({ id }) => {
  const [countries, setCountries] = useState([]);
  const [address, setAddress] = useState([]);
  const [mainAdress, setMainAdress] = useState([]);

  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    axios
      .get(`api/get_countries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(
    (id) => {
      axios
        .get(`api/get_addresses_by_country`)
        .then((res) => {
          setAddress(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [id]
  );

  console.warn("maindd", mainAdress);

  useEffect(() => {
    const arr = countries?.map((val) => {
      const findAddress = address?.filter(
        (filterAdress) => val?.id === filterAdress?.country_id
      );
      return {
        ...val,
        findAddress,
      };
    });
    setMainAdress(arr);
  }, [countries, address]);

  console.warn("here is add", address);

  const renderAccordion = (team, index) => {
    const handleAccordionToggle = (eventKey) => {
      setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey));
    };

    const maining = team?.findAddress;

    return (
      <>
        <Accordion className="contactAccordion" activeKey={activeKey}>
          <Accordion.Item eventKey={team} key={index}>
            <Accordion.Header
              eventKey={index.id}
              onClick={() => handleAccordionToggle(team)}
            >
              {team.country_title}
            </Accordion.Header>
            <Accordion.Body className="accoridionMain-body">
              {maining &&
                maining.map((mainingVal, keyVal) => {
                  return (
                    <>
                      <div className="contact-address-wrapper" key={keyVal}>
                        <div className="contact-box">
                          <h4>{mainingVal?.address_title}</h4>

                          <ul>
                            <li className="address-box">
                              <i className="fa fa-map-pin"></i>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: mainingVal ? mainingVal.address : "",
                                }}
                              />
                              <p />
                            </li>

                            {mainingVal?.phone ? (
                              <div>
                                <li
                                  onClick={() =>
                                    (window.location.href = `tel:${mainingVal.phone}`)
                                  }
                                >
                                  <i className="	fas fa-mobile-alt"></i>
                                  {mainingVal?.phone}
                                </li>
                              </div>
                            ) : (
                              ""
                            )}

                            {mainingVal?.telephone ? (
                              <div>
                                <li
                                  onClick={() =>
                                    (window.location.href = `tel:${mainingVal.telephone}`)
                                  }
                                >
                                  <i className="fa fa-phone "></i>
                                  {mainingVal?.telephone}
                                </li>
                              </div>
                            ) : (
                              ""
                            )}

                            {mainingVal?.email ? (
                              <div>
                                <li
                                  onClick={() =>
                                    (window.location.href = `mailto:${mainingVal.email}`)
                                  }
                                >
                                  <i className="fa fa-envelope"></i>
                                  {mainingVal?.email}
                                </li>
                              </div>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                    </>
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  };
  // useEffect(() => {
  //   nbData();
  // }, []);

  const [title] = useState("contact");

  return (
    <div>
      <Banner mainPage="" subPage="" title={title} />

      <>
        <ul>
          <li>
            <h2>{mainAdress.country_title}</h2>
          </li>
        </ul>
      </>
      <section className="container-fluid contact-page">
        <div className="row">
          <div className="container">
            <div className="contact-div animate__animated animate__fadeInDown">
              <h1>Get in touch</h1>

              <span className="line"></span>
              <div className="contact-form">
                <form className="contact_form2">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          data-validetta="required"
                          placeholder="Type your name"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Type your email"
                          className="form-control"
                          data-validetta="required"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          className="form-control"
                          data-validetta="required"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <select
                          name="branch"
                          data-validetta="required"
                          className="form-control"
                        >
                          <option value="">Select Location</option>
                          <option value="SINGAPORE - HEAD QUARTERS">
                            SINGAPORE - HEAD QUARTERS
                          </option>
                          <option value="MALAYSIA">MALAYSIA</option>
                          <option value="INDIA">INDIA</option>
                          <option value="THAILAND">THAILAND</option>
                          <option value="INDONESIA">INDONESIA</option>
                          <option value="SRILANKA ">SRILANKA </option>
                          <option value="MYANMAR">MYANMAR</option>
                          <option value="PAKISTAN">PAKISTAN</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="purp"
                          placeholder="Purpose"
                          className="form-control"
                          data-validetta="required"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="method"
                          placeholder="Transportation Method"
                          className="form-control"
                          data-validetta="required"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="message"
                          placeholder="Comment"
                          className="form-control"
                          data-validetta="required"
                        />
                      </div>
                    </div>

                    <div className="col-xs-12 text-center form-b mail_send">
                      <a href="">Submit</a>
                    </div>
                  </div>
                  <div className="alert">
                    <span
                      className="closebtn"
                      onclick="this.parentElement.style.display='none';"
                    >
                      &times;
                    </span>
                    Thank you for contacting with us.
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid location-sec">
        <div className="row">
          <div className="container location-div">
            <h2 className="text-center text-light">Contact by location</h2>

            <div>{mainAdress.map(renderAccordion)}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
