import Header from "../Combonent/Header";
import Home from "../Pages/Home/Index.js";
import Footer from "../Combonent/Footer";
import AboutUs from "../Pages/Aboutus/AboutUs";

import Calltoaction from "../Combonent/Calltoaction";

import Contact from "../Pages/Contact/Contact";
import Career from "../Pages/Career/Career";
import CareerForm from "../Pages/CareerForm/CareerForm";

import Services from "../Pages/services/Services.js";
import { Route, Routes } from "react-router-dom";
import Whereweare from "../Pages/WhereWeAre/Whereweare";
import Detailswhereweare from "../Pages/WhereWeAre/DetailsWhereweare";
import CareerDetails from "../Pages/CareerDetails/CareerDetails";
import HandleTitle from "../Pages/Home/HandleTitle.jsx";

const MainLayout = () => {
  return (
    <>
      <Header />
      <HandleTitle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />

        <Route path="/service/:userId" element={<Services />} />

        <Route path="/contact-us" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career-form" element={<CareerForm />} />

        <Route path="/where-we-are" element={<Whereweare />} />
        <Route path="/:whereWeid" element={<Detailswhereweare />} />
        <Route path="/career/:careerData" element={<CareerDetails />} />
      </Routes>

      <Calltoaction />
      <Footer />
    </>
  );
};

export default MainLayout;
