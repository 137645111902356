import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";

const HomeStrip = () => {
  const [fmModal, setFmModal] = useState(false);
  const [smModal, setSmModal] = useState(false);
  const [scrolling, setScrolling] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolling(false);
      } else {
        setScrolling(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`your-default-class ${scrolling ? "isSticky" : ""}`}>
      <Modal
        size="lg"
        show={fmModal}
        onHide={() => setFmModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Customer Portal - Please Select Your Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div class="modal-body">
              <div class="modal-body d-flex justify-content-center align-items-center">
                <div class="row">
                  <div class="col-lg-4 mb-2">
                    <a
                      class="btn"
                      href="https://cp.onlinetracking.co/#/login/54"
                      target="_blank"
                    >
                      Singapore
                    </a>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <a
                      class="btn"
                      href="https://cp.onlinetracking.co/#/login/101"
                      target="_blank"
                    >
                      Pakistan{" "}
                    </a>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <a
                      class="btn"
                      href="https://cp.onlinetracking.co/#/login/232"
                      target="_blank"
                    >
                      Bangladesh
                    </a>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <a
                      class="btn"
                      href="https://cp.onlinetracking.co/#/login/97"
                      target="_blank"
                    >
                      SriLanka
                    </a>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <a
                      class="btn"
                      href="https://cp.onlinetracking.co/#/login/87"
                      target="_blank"
                    >
                      Myanmar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={smModal}
        onHide={() => setSmModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body d-flex justify-content-center align-items-center">
            <div className="row">
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://pp.onlinetracking.co/auth/login/54"
                  target="_blank"
                >
                  Singapore
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/101"
                  target="_blank"
                >
                  Pakistan{" "}
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/232"
                  target="_blank"
                >
                  Bangladesh
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/97"
                  target="_blank"
                >
                  SriLanka
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/87"
                  target="_blank"
                >
                  Myanmar
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <section className="under-strip">
        <div className="container">
          <div className="strip-wrapper">
            <div className="strip-card">
              <button onClick={() => setFmModal(true)}>
                <i className="fa fa-users"></i> CUSTOMER PORTAL
              </button>
            </div>
            <div className="strip-card">
              <button onClick={() => setSmModal(true)}>
                <i className="fa fa-user-circle"></i> Partner Portal
              </button>
            </div>
            <div className="strip-card">
              <button>
                <i className="fa fa-compress"></i> Sailing Schedule
              </button>
            </div>
            <div className="strip-card">
              <button>
                <i className="fa fa-ship"></i> Tracking
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeStrip;
