// export const baseUrl = window.location.origin + "gcs/adminpanel/";
// export const imageUrl = window.location.origin + "gcs/adminpanel/public/";

// export const baseUrl = "http://127.0.0.1:8000/";
// export const imageUrl = "http://127.0.0.1:8000/";

export const baseUrl = "https://adminpanel.mindwalk.space/";
export const imageUrl = "https://adminpanel.mindwalk.space/public/";



