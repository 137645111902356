import React, { useState, useEffect } from "react";
import Banner from "../../Combonent/Banner";
import axios from "../../utils/axios";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../utils/constants";
import { UserType } from "../../Combonent/Header";

const Services = () => {
  const [servdetails, setServDetails] = useState();

  const { userId } = useParams();

  useEffect(() => {
    axios.get(`api/get_subservices`).then((response) => {
      const filterdServices = response.data.data.find(
        (value) => value.id == userId
      );

      setServDetails(filterdServices);
      console.warn(filterdServices);
    });
  }, [userId]);

  // useEffect(() => {
  //   fetch(`${baseUrl}/api/get_subservice/${userId}`)
  //     .then((response) => response.json())
  //     .then((data) => setServDetails(data.data));
  // });

  console.warn(setServDetails);
  console.warn(userId);
  return (
    <div>
      <Banner
        mainPage={""}
        subPage="Services"
        title={servdetails ? servdetails.main_title : ""}
      />
      {servdetails && (
        <section class="inner-content">
          <div
            key={servdetails.id}
            class="nz-section horizontal autoheight-true animate-false full-width-false "
            data-animation-speed="35000"
            data-parallax="false"
            style={{ paddingTop: "75px" }}
          >
            <div class="container">
              <div class="nz-row">
                <div
                  class="col col-sm-12 col-lg-6 vc_col-md-12 col-xs-12 col-12  element-animate-false valign-top"
                  data-effect="none"
                  data-align="left"
                >
                  <div class="col-inner">
                    <h2
                      style={{
                        fontSize: "30px",
                        color: "#333333",
                        lineHeight: "40px",
                        textAlign: "left",
                      }}
                      class="vc_custom_heading"
                    >
                      {servdetails ? servdetails.sub_title_1 : ""}
                    </h2>
                    <div
                      class="sep-wrap element-animate element-animate-false left nz-clearfix"
                      data-effect="none"
                    >
                      <div
                        class="nz-separator solid"
                        style={{
                          borderBottomColor: "#ffb700",
                          width: "100px",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        &nbsp;
                      </div>
                    </div>
                    <div
                      class="nz-column-text nz-clearfix  element-animate-false"
                      data-effect="none"
                      data-effect-speed="50"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: servdetails ? servdetails.description_1 : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col col-sm-12 ol-lg-6 col-md-12 col-xs-12 col-12  element-animate-false valign-middle"
                  data-effect="none"
                  data-align="left"
                >
                  <div class="col-inner" style={{ paddingLeft: "20px" }}>
                    <img
                      class="alignright  "
                      src={`${imageUrl}images/${
                        servdetails ? servdetails.image_name : ""
                      }`}
                      alt="7584"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="nz-section horizontal autoheight-false animate-false full-width-false "
            data-animation-speed="35000"
            data-parallax="false"
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <div class="container">
              <div class="nz-row">
                <div
                  class="col col-sm-12 col-12  element-animate-false valign-top"
                  data-effect="none"
                  data-align="left"
                >
                  <div class="col-inner">
                    <h2
                      style={{
                        fontSize: "30px",
                        color: " #333333",
                        lineHeight: "40px",
                        textAlign: "left",
                        marginLeft: "0rem",
                      }}
                      class="vc_custom_heading"
                    >
                      {servdetails ? servdetails.sub_title_2 : ""}
                    </h2>
                    <div class="gap nz-clearfix" style={{ height: "25px" }}>
                      &nbsp;
                    </div>
                    <div
                      class="nz-column-text nz-clearfix  element-animate-false m-r"
                      data-effect="none"
                      data-effect-speed="50"
                    >
                      <p style={{ marginLeft: "0rem" }}>
                        Our unique E-Commerce services include the use of
                        technological tools and digital solutions to enable our
                        customers to run their ‘online shop’.
                      </p>

                      <ul
                        style={{ marginLeft: "1rem" }}
                        dangerouslySetInnerHTML={{
                          __html: servdetails ? servdetails.description_1 : "",
                        }}
                      />

                      <p
                        style={{ marginLeft: "1rem" }}
                        dangerouslySetInnerHTML={{
                          __html: servdetails ? servdetails.description_2 : "",
                        }}
                      />

                      <p>
                        It is our partnerships that make us successful as we
                        work diligently with our valuable clients to improve
                        upon and provide the best innovative solutions through
                        the supply chain process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Services;
