
import MainLayout from "./Layout/MainLayout"



function App() {

  return (
  <>
    <MainLayout/>
    <div className="container">

    </div>
  </>
  );
}

export default App;
