import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { imageUrl } from "../../utils/constants";
// Import Swiper styles

import "swiper/css/navigation";

import { Navigation } from "swiper";
import axios from "..//..//utils/axios";
const Banner = () => {
  const [bannerContent, setBannerContent] = useState("");

  useEffect(() => {
    axios
      .get("api/get_banner")
      .then((response) => {
        setBannerContent(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(bannerContent);
  return (
    <>
      <div className="banner-swipering">
        <Swiper
          navigation={true}
          modules={[Navigation, Autoplay]}
          className="mySwiper"
          loop={true}
          autoplay={{
            delay: 4000,
          }}
        >
          {bannerContent &&
            bannerContent.map((banner, i) => {
              return (
                <SwiperSlide>
                  <div className="swiper-slide" key={i}>
                    <div className="tp-dottedoverlay tp-ov-background"></div>
                    <img
                      src={`${imageUrl}images/${banner.image_name}`}
                      className="img-fluid"
                      alt=""
                    />

                    <div className="banner-content wow fadeInUp">
                      <div className="container">
                        <h1>{banner?.main_title}</h1>
                        <h2>{banner?.caption}</h2>
                        <div className="main-head-buttons">
                          <button className="main-first">What we offer</button>
                          <button className="main-second">Get a quote</button>
                        </div>
                      </div>
                    </div>

                    <div className="BannerOvarlay"></div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default Banner;
